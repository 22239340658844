import { Col, Input, Row } from "antd";
import React, { useState } from "react";
// import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import IMAGES from "../const/Image";
// import { UserServices } from "../../../redux/Services";
// import { validateLogin } from "../../../utils/validation";
// import LeftSection from "../Component/LeftSection";
// import IMAGES from "../../../const/Image";
import { validateLogin } from "../utils/validation";
import LeftSection from "./LeftSection";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const [show, setShow] = useState(false);
  //   const { mutate: Login } = useMutation((data) =>
  //     dispatch(UserServices.loginApi(data, navigate))
  //   );
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    const validationErrors = validateLogin(values);
    let noErrors = Object.keys(validationErrors).length == 0;
    setErrors(validationErrors);
    if (noErrors) {

      // userLogin


      //   Login(values);

      dispatch(allapiAction.userLogin({ email: values?.Email, password: values?.Password }, navigate));
      return () => { };
    }
  };
  return (
    <div className="flex " style={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

      <div className="" style={{ background: '#06ad83', padding:'30px', borderRadius: '10px', paddingBottom: '70px' }}>
        <div className="flex ">
          <img src={IMAGES.LogoHeader} alt="" className="scale_img mb-30" />
        </div>

        <p className="fw-600 fs-16" style={{ fontFamily: 'sans-serif', textAlign: 'center', fontSize: '20px', color: "white" }}>Sign in to your account</p>
        <div className="loginForm">
          <div className="form-group">
            <div className="input-group">
              <input type="text"
        
                autoComplete="new-off"
                placeholder="Email"
                name="Email"
                onChange={handleChange}
                value={values?.Email || ""} 

                style={{padding: '5px', outline: 'none', background: 'white', width: '230px', border: 'none', borderRadius: '5px' }}
                />


            </div>
            {errors?.Email && <p className="error">{errors?.Email}</p>}
          </div>
          <div className="form-group">
            <div className="input-group">

              <input type={show ? "text" : "password"}
                 style={{padding: '5px', outline: 'none', background: 'white', width: '230px', border: 'none', borderRadius: '5px' }}
                autoComplete="new-off"
                placeholder="Password"
                name="Password"
                onChange={handleChange}
                value={values?.Password || ""} />

            </div>
            {errors?.Password && (
              <p className="error">{errors?.Password}</p>
            )}
          </div>
          {/* <div className='box_footer'>
                                <div><Link className="fw-500 f-pass" to={"/forgot-password"}>Forgot password?</Link></div>
                            </div> */}
          <button
            type="button"
            className=" "
            style={{ background: 'white', border: 'none', borderRadius: '5px', padding: '5px', width: '100%', marginTop: '10px' }}
            onClick={() => handleLogin()}
          >
            Sign In
          </button>
        </div>
      </div>

    </div>
  );
};

export default Login;
