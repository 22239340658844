import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";
import {
  LOADING,
  CREATE_CATEGORIES,
  GET_CATEGORIES,
  GET_SUB_SUB_CATEGORIES,
  GET_SELLER_PRODUCT_LIST,
  GET_SUB_SUB_CATE_LIST,
  OTP_BOX,
  GET_BUSS_PRO,
  GET_PACKAGE_LIST,
  GET_SELLER_TANSACTION,
  GET_PROFILE,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_PRODUCT_DETAILS,
  GET_SELLER_PRODUCT_LIST_PUBLICE,
  GET_TAGS_PRODUCT_LIST_PUBLICE,
  GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY,
  GET_SELLER_PROFILE,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_PAGES,
  GET_EMAIL_QU,
  GET_WHATSAPP_QU,
  PAGE_DETAILS,
  SEARCH_TAGS,
  SEARCH_PRODUCT_PUB,
  GET_FOOTER_DATA_ALL,
  GET_HOME_ALL_PRO,
  TOP_VIEW_ALL_TYPE,
  SELLER_LIST,
  DESHBOARD_STATE,
  SLIDER_LIST,
  BLOG_LIST,
  BLOG_DETAILS,
  GET_EXPIRE_DATE,
  HOME_POPS,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  WITHDRAWL_LIST,
  KYC_LIST,
  SETTING,
  DLS_LIST,
  DRS_LIST,
  HOME_LIST,
  POLLS_LISTS,
  RECENT_MATCH_LISTS,
  POLL_LISTS,
} from "../common/constant";
// const Token = require("../../Utils/Auth/Token");

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const createcategoriesAction = (data) => {
  return { type: CREATE_CATEGORIES, payload: data };
};
const getcategoriesAction = (data) => {
  return { type: GET_CATEGORIES, payload: data };
};
const getsubsusbcategoriesAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORIES, payload: data };
};
const getsubsusbcategoriesfillAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST_FIL, payload: data };
};
const getsellerProductListAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST, payload: data };
};
const getsellerProductListPublicAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE, payload: data };
};
const gettagsProductListPublicAction = (data) => {
  return { type: GET_TAGS_PRODUCT_LIST_PUBLICE, payload: data };
};
const getsellerProductListPublicbycategoryAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY, payload: data };
};
const getsubsusbcateListAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST, payload: data };
};
const otpBoxAction = (data) => {
  return { type: OTP_BOX, payload: data };
};
// const otpBoxForgotAction = (data) => {
//   return { type: OTP_BOX_FORGOT, payload: data };
// };
const getBussnissProfileAction = (data) => {
  return { type: GET_BUSS_PRO, payload: data };
};
const getpackageListAction = (data) => {
  return { type: GET_PACKAGE_LIST, payload: data };
};
const getticketListAction = (data) => {
  return { type: GET_SELLER_TICKET_LIST, payload: data };
};
const gettansactionListAction = (data) => {
  return { type: GET_SELLER_TANSACTION, payload: data };
};
const getprofileAction = (data) => {
  return { type: GET_PROFILE, payload: data };
};
const getmessageListAction = (data) => {
  return { type: GET_MESSAGE_LISTS, payload: data };
};
const getinvoiceAction = (data) => {
  return { type: GET_INVOICE, payload: data };
};
const getproductDetailsAction = (data) => {
  return { type: GET_PRODUCT_DETAILS, payload: data };
};
const getSellerDetailsAction = (data) => {
  return { type: GET_SELLER_PROFILE, payload: data };
};
const getPagesAction = (data) => {
  return { type: GET_PAGES, payload: data };
};
const getemailQuAction = (data) => {
  return { type: GET_EMAIL_QU, payload: data };
};
const getWhatsappQuAction = (data) => {
  return { type: GET_WHATSAPP_QU, payload: data };
};
const getpageDetailsAction = (data) => {
  return { type: PAGE_DETAILS, payload: data };
};
const getsearchtagssAction = (data) => {
  return { type: SEARCH_TAGS, payload: data };
};
const getsearchProductPubAction = (data) => {
  return { type: SEARCH_PRODUCT_PUB, payload: data };
};
const getfooterAllDataPubAction = (data) => {
  return { type: GET_FOOTER_DATA_ALL, payload: data };
};
const gethomeAllProAction = (data) => {
  return { type: GET_HOME_ALL_PRO, payload: data };
};
const topViewAllTypeAction = (data) => {
  return { type: TOP_VIEW_ALL_TYPE, payload: data };
};
const getSellerListPublicAction = (data) => {
  return { type: SELLER_LIST, payload: data };
};
const sellerDashboardStateAction = (data) => {
  return { type: DESHBOARD_STATE, payload: data };
};
const sliderListAction = (data) => {
  return { type: SLIDER_LIST, payload: data };
};
const blogListAction = (data) => {
  return { type: BLOG_LIST, payload: data };
};
const blogDetailsAction = (data) => {
  return { type: BLOG_DETAILS, payload: data };
};
const getExpireDateAction = (data) => {
  return { type: GET_EXPIRE_DATE, payload: data };
};
const homepopAction = (data) => {
  return { type: HOME_POPS, payload: data };
};

// new
const userListAction = (data) => {
  return { type: USERS_LIST, payload: data };
};
const userContactListAction = (data) => {
  return { type: USERS_CONTACT_LIST, payload: data };
};

const faqsLsitAction = (data) => {
  return { type: FAQS_LIST, payload: data };
};
const categoryLsitAction = (data) => {
  return { type: CATEGORY_LIST, payload: data };
};
const questionLsitAction = (data) => {
  return { type: QUESTION_LIST, payload: data };
};
const deshboardAction = (data) => {
  return { type: DESHBOARD, payload: data };
};
const quizLsitAction = (data) => {
  return { type: QUIZ_LIST, payload: data };
};
const withdrawalListAction = (data) => {
  return { type: WITHDRAWL_LIST, payload: data };
};
const kycListAction = (data) => {
  return { type: KYC_LIST, payload: data };
};
const settingAction = (data) => {
  return { type: SETTING, payload: data };
};
const dlsListAction = (data) => {
  return { type: DLS_LIST, payload: data };
};
const drsListAction = (data) => {
  return { type: DRS_LIST, payload: data };
};
const homeListAction = (data) => {
  return { type: HOME_LIST, payload: data };
};
const pollListAction = (data) => {
  return { type: POLLS_LISTS, payload: data };
};
const recentmatchListAction = (data) => {
  return { type: RECENT_MATCH_LISTS, payload: data };
};

export const getpollslistsAdmin = async (data, history) => {

  try {
    const token = await localStorage.getItem("admin_token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const response = await api.get(
      `${URL.getpollsListAdmin}`,
      config
    );

    return response?.data?.data;
  } catch (error) {
    return error
  }

};
export const recentmatchList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.recentmatchList}`, config);
      // dispatch(sliderListAction(response?.data?.data));
      console.log(response?.data);
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(recentmatchListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deletePollsList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.deletePollsList}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(pollListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createPollsList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.createPollsList}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(pollListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gethomeListAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.gethomeListAdmin}`, config);
      // dispatch(sliderListAction(response?.data?.data));
      console.log(response?.data);
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(homeListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteDrs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.deleteDrs}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(drsListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createDrs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.createDrs}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(drsListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getDrsAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getDrsAdmin}/${data}`, config);
      // dispatch(sliderListAction(response?.data?.data));
      console.log(response?.data);
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(drsListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getDlsAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getDlsAdmin}/${data}`, config);
      // dispatch(sliderListAction(response?.data?.data));
      console.log(response?.data);
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(dlsListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteDls = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.deleteDls}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(dlsListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createDls = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.createDls}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(dlsListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createPrizeSetting = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(
        `${URL.createPrizeSetting}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(settingAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPrizeSetting = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPrizeSetting}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(settingAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const userLogin = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.login_admin}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);
        console.log(response?.data);
        if (response?.data?.status == true) {
          // dispatch(otpBoxAction(true));
          localStorage.setItem("admin_token", response?.data?.token);
          navigate("/");
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate("/vendor"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const dashboard = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.dashboard}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(deshboardAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getUserList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deletebyadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.deletebyadmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getuserListSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchUserAdmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

//

export const getDoucmentList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getDoucmentList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(kycListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const updateDoucment = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.updateDoucment}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.msg);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(kycListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.msg);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const searchDoucment = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.searchDoucment}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(kycListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const withdrawalListAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.withdrawalListAdmin}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(withdrawalListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const withdrawalListUpdateByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(
        `${URL.withdrawalListUpdateByAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(withdrawalListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const withdrawalListSearchByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(
        `${URL.withdrawalListSearchByAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(withdrawalListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSlider = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.createSlider}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteSlider = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.deleteSlider}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateStatusSlider = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(
        `${URL.updateStatusSlider}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const sendNotification = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'multipart/form-data'
        },
      };
      // dispatch(loading(true));

      console.log(data);

      // user_inactive
      const response = await api.post(`${URL.sendNotification}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));

      // console.log(response);
      
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(`${URL.users}?page=${data?.page}`, config);
        // dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getsliderList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsliderList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsliderList2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getsliderList}/${data}`, config);
      // dispatch(sliderListAction(response?.data?.data));
      console.log(response?.data);
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const allcategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_category_main}?page=${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const allcategorySearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_category_main}?page=${data?.page}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const update_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const create_quiz_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.create_quiz_category}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const create_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.create_quiz}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const update_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_quiz}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_quiz}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}&quiz_id=${data?.quiz_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const quiz_list = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.quiz_list}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const quiz_listSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.quiz_list}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const questionsLlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.questions_list}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const questionsLlistSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.questions_list}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_question}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}&question_id=${data?.question_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const update_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_question}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const create_quiz_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.create_quiz_question}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const usercontact = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.user_contact}?page=${data?.page}&user_id=${data?.user_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userContactListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        dispatch(userContactListAction({}));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const usercontactser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.user_contact}?page=${data?.page}&user_id=${data?.user_id}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userContactListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        dispatch(userContactListAction({}));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// old

export const getlistfaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getlistfaqs}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(faqsLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const gethomePop = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.gethomePop}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(homepopAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogListPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBlogListPub}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getExpireDate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getExpireDate}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getExpireDateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDashboardState = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.sellerDashboardState}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(sellerDashboardStateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerListPublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSellerListPublic}`,
        data,
        config
      );
      dispatch(getSellerListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const topViewnewSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.topViewnewSeller}`, data, config);
      dispatch(topViewAllTypeAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getBlogDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getBlogDetails}`, data, config);
      dispatch(blogDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getHomePro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getHomePro}`, data, config);
      dispatch(gethomeAllProAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPagepageListetPu = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.footerPagepageListetPu}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfooterAllDataPubAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const passwordChange = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.passwordChange}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const sendOtpSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.sendOtpSeller}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchProductpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProductpub}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProducsdft = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtags = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchtags}`, data, config);
      dispatch(getsearchtagssAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const pageDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.pageDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getpageDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPages = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPages}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        dispatch(getPagesAction(response?.data?.data));
        // toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
