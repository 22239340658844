import firebase from "firebase";
// import { getMessaging, getToken, onMessage } from "firebase";

const firebaseConfig = {
  // apiKey: "AIzaSyDk8bZbCySRuItJPy1rsnoba046zDnXeVk",
  // authDomain: "starlive-5b933.firebaseapp.com",
  // databaseURL: "https://starlive-5b933-default-rtdb.firebaseio.com",
  // projectId: "starlive-5b933",
  // storageBucket: "starlive-5b933.appspot.com",
  // messagingSenderId: "244314907459",
  // appId: "1:244314907459:web:c50ad6f8eb68421e6845f6",
  // measurementId: "G-SNXG0MDKMP"

  // apiKey: "AIzaSyBhkRcD4y80b3-_NZqvIlVU57QjC-0xkj0",
  // authDomain: "premiumliveline-2806e.firebaseapp.com",
  // databaseURL: "https://premiumliveline-2806e-default-rtdb.firebaseio.com",
  // projectId: "premiumliveline-2806e",
  // storageBucket: "premiumliveline-2806e.appspot.com",
  // messagingSenderId: "845568266199",
  // appId: "1:845568266199:web:12a977ac351a6d927b8592",
  // measurementId: "G-8M71RYPHCS",


  apiKey: "AIzaSyCCmWlQGTAsDIxBnG_6UvmKiSogmvmPA7E",
  authDomain: "cricbuzzplus-d3f94.firebaseapp.com",
  databaseURL: "https://cricbuzzplus-d3f94-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cricbuzzplus-d3f94",
  storageBucket: "cricbuzzplus-d3f94.appspot.com",
  messagingSenderId: "246030429456",
  appId: "1:246030429456:web:bf23cf9842f639fe0e4995",
  measurementId: "G-58V6W4LRWW"
};

const fb = firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
const db = firebase.firestore();
var database = firebase.database();
const storage = firebase.storage();
// const messaging = firebase.getMessaging()
// const getToken = firebase.getToken()
// const onMessage = onMessage()

export { auth, firebase, db, storage, database };

// export const getTokens = (setTokenFound) => {
//   return getToken(messaging, {vapidKey: 'GENERATED_MESSAGING_KEY'}).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       // setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       // setTokenFound(false);
//       // shows on the UI that permission is required
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }
