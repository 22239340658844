import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteToken } from '../../utils';

const TopBar = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        DeleteToken();
        navigate("/login", { replace: true });
    }
    return (
        <div className='flex justify-content-between align-items-center' style={{ background: 'white', height: '64px' }}>
            {/* <div style={{ color: '&#8592;' }}>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',

                onClick: () => setCollapsed(!collapsed),
            })}
            </div> */}

            <div onClick={() => setCollapsed(!collapsed)} style={{ padding: '10px' }}>

                {
                    collapsed ? (
                        <MenuUnfoldOutlined />
                    ) : (
                        <MenuFoldOutlined />
                    )

                }


            </div>
            <div className='mr-20'>
                <Button className='flex justify-content-center align-items-center '
                    onClick={() => handleLogout()}>Logout<LogoutOutlined />
                </Button>
            </div>
        </div >
    )
}

export default TopBar