export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:5600",
  API_BASE_URL: "https://backend.jaipuracre.com",

  // apipublic

  login_admin: "/users/login_admin",

  dashboard: "/adminApi/dashboard",
  getUserList: "/adminApi/getUserList",
  deletebyadmin: "/adminApi/deletebyadmin",
  searchUserAdmin: "/adminApi/searchUserAdmin",

  // withdrawal
  withdrawalListAdmin: "/adminApi/withdrawalListAdmin",
  withdrawalListUpdateByAdmin: "/adminApi/withdrawalListUpdateByAdmin",
  withdrawalListSearchByAdmin: "/adminApi/withdrawalListSearchByAdmin",

  // kyc
  getDoucmentList: "/adminApi/getDoucmentList",
  updateDoucment: "/adminApi/updateDoucment",
  searchDoucment: "/adminApi/searchDoucment",
  gethomeListAdmin: "/adminApi/gethomeListAdmin",

  // slider
  createSlider: "/adminApi/createSlider",
  getsliderList: "/adminApi/getsliderList",
  deleteSlider: "/adminApi/deleteSlider",
  updateStatusSlider: "/adminApi/updateStatusSlider",
  sendNotification: "/adminApi/sendNotificationCu",
  createPrizeSetting: "/adminApi/createPrizeSetting",
  getPrizeSetting: "/adminApi/getPrizeSetting",
  getUserExport: "/adminApi/getUserExport",
  getDlsAdmin: "/adminApi/getDlsAdmin",
  createDls: "/adminApi/createDls",
  createDrs: "/adminApi/createDrs",
  deleteDls: "/adminApi/deleteDls",
  deletePollsList: "/adminApi/deletePollsList",
  deleteDrs: "/adminApi/deleteDrs",
  getDrsAdmin: "/adminApi/getDrsAdmin",
  createPollsList: "/adminApi/createPollsList",
  recentmatchList: "/adminApi/recentmatchList",
  getpollslistsAdmin: "/adminApi/getpollslistsAdmin",

  users: "/api/users",
  user_contact: "/api/user_contact",
  all_category: "/api/all_category",
  all_category_main: "/api/all_category_main",
  delete_category: "/api/delete_category",
  update_category: "/api/update_category",
  create_quiz_category: "/api/create_quiz_category",
  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",
  user_allcontact: "/api/user_allcontact",
  user_inactive: "/api/user_inactive",
  quiz_active: "/api/quiz_active",

  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin

  // polls
  getpollsListAdmin: "/adminApi/getpollsListAdmin",
};
