import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Boxes = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
  console.log(deshboard);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  return (
    <div className="row  justify-content-md-center">
      <div
        className=" p-10 dashboardBox cursor-pointer w-200 col m-6"
        onClick={() => navigate("/users")}
      >
        <div className="flex justify-content-between align-item-center">
          <div className="relative boxHeadingTotal fw-600">Total User</div>
        </div>

        <div className="flex justify-content-end align-item-center">
          <div className="priceText">{deshboard?.total_user || 0}</div>
        </div>
      </div>

      <div
        className=" p-10 dashboardBox cursor-pointer col  m-6"
      // onClick={() => navigate("/users")}
      >
        <div className="flex justify-content-between align-item-center">
          <div className="relative boxHeadingRisk fw-600">Total Poll</div>
        </div>
        <div className="flex justify-content-end align-item-center">
          <div className="priceText color-risk">
            {deshboard?.total_poll || 0}
          </div>
        </div>
      </div>

      <div
        className=" p-10 dashboardBox cursor-pointer col  m-6"
        onClick={() => navigate("/withdrawal")}
      >
        <div className="flex justify-content-between align-item-center">
          <div className="relative boxHeadingTotal fw-600">
            Total withdrawals
          </div>
        </div>
        <div className="flex justify-content-end align-item-center">
          <div className="priceText">{deshboard?.total_withdrawals || 0}</div>
        </div>
      </div>

      <div
        className=" p-10 dashboardBox cursor-pointer col  m-6"
        onClick={() => navigate("/kyc")}
      >
        <div className="flex justify-content-between align-item-center">
          <div className="relative boxHeadingRisk fw-600">Total Kyc</div>
        </div>
        <div className="flex justify-content-end align-item-center">
          <div className="priceText color-risk">
            {deshboard?.total_kyc || 0}
          </div>
        </div>
      </div>
    </div>

  );
};

export default Boxes;
